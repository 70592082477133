<app-header></app-header>
<div class="spinner-div">
  @if(isLoading){
  <mat-spinner></mat-spinner>
  }
</div>
<div class="contactUsContainer">
  <div class="contactUsHome col-lg-12">
    <img src="./assets/images/contact-us-banner.png" class="contactUsHomeImg" />
    <div class="contactUsContent">
      <div class="contactUsHeading">Contact us</div>
      <div class="contactUsChildHeading">
        Please use the form below to get in touch with us.
      </div>
    </div>
  </div>

  <div class="col-lg-12 col-md-12">
    <div class="contactUsSubtitle">
      <div class="contactUsSubtitleContent">
        Certificate checker support request
      </div>
      <div class="contactUsContainerCard">
        <div
          class="row contactForm col-lg-12 col-md-12 col-sm-12"
          id="contactUsForm"
        >
          <form
            [formGroup]="supportForm"
            #formDirective="ngForm"
            (ngSubmit)="onSubmit()"
            class="contactUsFormElement"
          >
            <div class="row contactForm">
              <div class="col-lg-6 col-md-6">
                <mat-form-field>
                  <mat-label>Full Name</mat-label>
                  <input
                    matInput
                    formControlName="nameControl"
                    name="name"
                    placeholder="Full Name"
                    (keydown.Space)="onSpaceKeyPress('nameControl')"
                  />
                  @if(supportForm.get('nameControl')?.hasError('pattern')) {
                  <mat-error
                    >Should not start with a special character</mat-error
                  >
                  }
                </mat-form-field>
              </div>
              <div class="contactUsRightFormFields col-lg-6 col-md-6">
                <mat-form-field>
                  <mat-label>Company Name</mat-label>
                  <input
                    matInput
                    formControlName="companyNameControl"
                    name="companyName"
                    placeholder="Company Name"
                    (keydown.Space)="onSpaceKeyPress('companyNameControl')"
                  />
                  @if(supportForm.get('companyNameControl')?.hasError('pattern'))
                  {
                  <mat-error
                    >Should not start with a special character</mat-error
                  >
                  }
                </mat-form-field>
              </div>
            </div>
            <div class="row contactForm">
              <div class="col-lg-6 col-md-6">
                <mat-form-field>
                  <mat-label>Email Address</mat-label>
                  <input
                    matInput
                    formControlName="emailControl"
                    name="email"
                    placeholder="Email Address"
                    (keydown.Space)="onSpaceKeyPress('emailControl')"
                  />
                  @if(supportForm.get('emailControl')?.hasError('email')) {
                  <mat-error> Please enter a valid email address. </mat-error>
                  } @if(supportForm.get('emailControl')?.hasError('required')) {
                  <mat-error> This field is required. </mat-error>
                  }
                </mat-form-field>
              </div>

              <div class="contactUsRightFormFields col-lg-6 col-md-6">
                <mat-form-field>
                  <mat-label>What do you need support with</mat-label>
                  <mat-select
                    (selectionChange)="loadDescription($event.value)"
                    formControlName="descriptionControl"
                  >
                    @for(data of dropDownValues; track data;) {
                    <mat-option [value]="data.value">
                      {{ data.text }}
                    </mat-option>
                    }
                  </mat-select>
                  @if(supportForm.get('descriptionControl')?.hasError('required'))
                  {
                  <mat-error> This field is required. </mat-error>
                  }
                </mat-form-field>
              </div>
            </div>
            @if(isDescription) {
            <div class="row contactForm">
              <div class="col-lg-6 col-md-6">
                <mat-form-field>
                  <mat-label> {{ descriptionLabel }} </mat-label>
                  <textarea
                    matInput
                    placeholder="{{ descriptionLabel }}"
                    formControlName="notesControl"
                  >
                  </textarea>
                </mat-form-field>
              </div>
            </div>
            }
            <div class="row contactUsTerms">
              <mat-checkbox
                class="row mat-checkbox"
                color="primary"
                formControlName="termsCheckboxControl"
              >
                <span
                  >By completing this form, I confirm that I have read the
                  <a
                    class="link"
                    href="https://www.dnv.com/privacy/"
                    target="_blank"
                    >privacy statement</a
                  >
                  and understood and accept the
                  <a
                    class="link"
                    href="https://www.dnv.com/terms/"
                    target="_blank"
                    >terms of use</a
                  >.
                </span>
                @if((supportForm.get('termsCheckboxControl').dirty ||
                isSubmitted) &&
                supportForm.get('termsCheckboxControl')?.hasError('required')) {
                <mat-error class="supportCheckboxError">
                  This field is required.
                </mat-error>
                }
              </mat-checkbox>
              <mat-checkbox
                class="row mat-checkbox"
                color="primary"
                formControlName="consentCheckboxControl"
              >
                <span
                  >I, hereby provide my consent to process above mentioned
                  personal information captured in this form for the fulfilment
                  of the request.</span
                >
                <br />
                <span
                  >I understand that, the above mentioned personal information
                  will be purged as per DNV’s Data Retention Policy once the
                  open requests are resolved and closed.</span
                >
                @if((supportForm.get('consentCheckboxControl').dirty ||
                isSubmitted) &&
                supportForm.get('consentCheckboxControl')?.hasError('required'))
                {
                <mat-error class="supportCheckboxError">
                  This field is required.
                </mat-error>
                }
              </mat-checkbox>
            </div>

            <div class="row">
              <div class="cc-recaptcha-field col-sm-12 mb-2">
                <re-captcha
                  #recaptcha
                  id="recaptcha"
                  name="recaptcha"
                  formControlName="recaptchaControl"
                  required
                ></re-captcha>
                <div class="recaptcha-error">
                  @if(supportForm.get('recaptchaControl')?.hasError('required')
                  && isSubmitted) {
                  <div>This field is required.</div>
                  }
                </div>
              </div>

              <div class="contactUsSubmitButton">
                <button
                  [disabled]="isLoading"
                  class="contactUsBtn"
                  mat-raised-button
                  color="primary"
                  type="submit"
                >
                  Submit Support Request
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
