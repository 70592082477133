import { Component, Input, OnInit, VERSION, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, FormGroupDirective } from '@angular/forms';
import {
  DescriptionMessage,
  SupportUsDropdown,
  ToastMessage,
} from '../../constants/messages';
import { ToastService } from '../../services/toast.service';
import { RecaptchaComponent } from 'ng-recaptcha';
import { ContactUsService } from './contact-us.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrl: './contact-us.component.scss',
})
export class ContactUsComponent implements OnInit {
  isLoading: boolean = false;
  isSubmitted: boolean = false;
  token: string | undefined;
  isSearch: boolean = false;
  descriptionMsg = DescriptionMessage;
  isDescription: boolean = false;
  descriptionLabel: string = '';
  supportForm: any;
  dropDownValues = SupportUsDropdown;
  result: any;

  @ViewChild('recaptcha') captchaField: RecaptchaComponent =
    {} as RecaptchaComponent;

  @ViewChild('formDirective') formDirective: FormGroupDirective = {} as FormGroupDirective;

  constructor(
    private toaster: ToastService,
    private contactUsService: ContactUsService
  ) {
    this.token = undefined;
    this.dropDownValues = SupportUsDropdown;
    this.descriptionMsg = DescriptionMessage;
    this.initForm();
  }

  initForm() {
    this.supportForm = new FormGroup({
      nameControl: new FormControl('', [
        Validators.pattern(/^[a-zA-Z0-9 ][-@,_#$%&'()!.*?a-zA-Z0-9 ]*$/),
      ]),
      companyNameControl: new FormControl('', [
        Validators.pattern(/^[a-zA-Z0-9 ][-@,_#$%&'()!.*?a-zA-Z0-9 ]*$/),
      ]),
      emailControl: new FormControl('', [
        Validators.required,
        Validators.email,
      ]),
      descriptionControl: new FormControl('',Validators.required),
      notesControl: new FormControl(''),
      termsCheckboxControl: new FormControl(false, [Validators.requiredTrue]),
      consentCheckboxControl: new FormControl(false, [Validators.requiredTrue]),
      recaptchaControl: new FormControl('', [Validators.required]),
    });    
  }

  ngOnInit(): void {}

  onSubmit() {
    // console.log(this.supportForm);
    this.token = this.supportForm.get('recaptchaControl').value;
    this.isSubmitted = true;
    if (this.supportForm.valid) {
      this.isLoading = true;
      this.isSearch = true;

      let data = {
        generic: {
          firstName: this.supportForm.get('nameControl').value?.trim(),
          lastName: this.supportForm.get('companyNameControl').value?.trim(),
          emailCust: this.supportForm.get('emailControl').value?.trim(),
          description: this.supportForm.get('descriptionControl').value?.trim(),
          notes: this.supportForm.get('notesControl').value?.trim(),
        },
      };
      this.contactUsService.postSupportRequest(data, this.token).subscribe({
        next: (res: any) => {
          this.result = res;
        },
        complete: () => {
          if (this.result) {
            this.toaster.success(
              ToastMessage.supportFormSubmittedSuccessfully,
              ''
            );
            this.formDirective.resetForm();
            this.supportForm.reset();
          }
          this.isLoading = false;
          this.isSubmitted = false;
          this.resetCaptcha();
        },
        error: (error) => {
          this.isLoading = false;
          this.isSubmitted = false;
          this.toaster.error(ToastMessage.supportFormError, '');
          this.resetCaptcha();
          console.log('error in support form API: ' + error);
        },
      });
    } else {
      this.resetCaptcha();
    }
  }

  resetCaptcha() {
    this.captchaField.reset();
  }

  isCaptcha() {
    return this.token && this.token.length > 0 ? true : false;
  }

  loadDescription(value: string) {
    if (value && value != '') {
      this.isDescription = true;
      this.descriptionLabel = this.descriptionMsg[value];
      this.supportForm.get('notesControl').setValue('');
    }
  }

  onSpaceKeyPress(control:string){
    if(this.supportForm.get(control).value?.trim() == '') return false;

    return true;
  }
}
