import { Component, ViewChild, ElementRef } from '@angular/core';
import { BUTTON_LABELS, APP_LABELS } from '../../../constants/utils';
import { SearchPageService } from './search-page.service';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { RecaptchaComponent } from 'ng-recaptcha';
import { environment } from '../../../../environments/environment';
import { MatButton } from '@angular/material/button';

@Component({
  selector: 'app-search-page',
  templateUrl: './search-page.component.html',
  styleUrl: './search-page.component.scss',
})
export class SearchPageComponent {
  isLoading: boolean = false;
  isSubmitted: boolean = false;
  token: string | undefined;
  isSearch: boolean = false;
  isSearchData: boolean = true;
  btnLabels = BUTTON_LABELS;
  appLabels = APP_LABELS;
  countries = [];
  certSearchValues: any = [];
  certSearchCount: number = 0;
  certSearchLimit: number = 15;
  searchFormGroup: any;
  certBaseUrl = environment.certificateBaseUrl;

  selectedFormValues = '';
  @ViewChild('searchBtnRef') buttonRef: MatButton = {} as MatButton;

  @ViewChild('recaptcha') captchaField: RecaptchaComponent =
    {} as RecaptchaComponent;

  FRM_CONTROLS = [
    'certNumberControl',
    'companyNameControl',
    'cityControl',
    'countryControl',
  ];

  constructor(
    private router: Router,
    private searchService: SearchPageService,
    private searchResultsElement: ElementRef
  ) {
    this.token = undefined;
    this.btnLabels = BUTTON_LABELS;
    this.appLabels = APP_LABELS;
    this.initForm();
  }

  initForm() {
    this.searchFormGroup = new FormGroup({
      certNumberControl: new FormControl(),
      companyNameControl: new FormControl(),
      cityControl: new FormControl(),
      countryControl: new FormControl(),
    });
  }
  ngOnInit(): void {
    this.certBaseUrl = environment.certificateBaseUrl;
    this.searchService.getCountryList().subscribe((res: any) => {
      if (res) this.countries = res.countryNames?.sort();
    });
  }

  onClear() {
    window.location.reload();
  }
  resetResults() {
    this.selectedFormValues = '';
    this.certSearchValues = [];
    this.certSearchCount = 0;
    this.certSearchLimit = 15;
  }

  onSubmit() {
    this.isSubmitted = true;
    if (this.searchFormGroup.valid && this.isFormValid()) {
      this.resetResults();
      this.isLoading = true;
      this.isSearch = true;

      let data = {
        displayName: this.searchFormGroup.get(this.FRM_CONTROLS[0]).value,
        accountName: this.searchFormGroup.get(this.FRM_CONTROLS[1]).value,
        city: this.searchFormGroup.get(this.FRM_CONTROLS[2]).value,
        country: this.searchFormGroup.get(this.FRM_CONTROLS[3]).value,
        captchaToken: this.token,
      };
      this.cleanObject(data);
      this.searchService.getCertificates(data).subscribe({
        next: (res: any) => {
          // console.log(res, 'search-page');

          if (res?.count) {
            this.isSearchData = true;
            this.certSearchValues = res.value;
            this.certSearchCount = res.count;
            this.certSearchLimit = res.limit;
          } else {
            this.selectedValues();
            this.isSearchData = false;
          }
        },
        complete: () => {
          // console.log(this.certSearchValues);
          this.isLoading = false;
          this.isSubmitted = false;
          this.resetCaptcha();
          this.gotoSearchResult();
        },
        error: (error) => {
          this.isLoading = false;
          this.isSubmitted = false;
          this.resetCaptcha();
          this.gotoSearchResult();
          console.log('error in certificate search API: ' + error);
        },
      });
    } else {
      this.resetCaptcha();
    }
  }

  resetCaptcha() {
    this.captchaField.reset();
  }

  isFormValid() {
    return this.isCaptcha() && !this.isEmptyForm() ? true : false;
  }

  isCaptcha() {
    return this.token && this.token.length > 0 ? true : false;
  }

  isEmptyForm() {
    return this.FRM_CONTROLS.every((val: string) => {
      return (
        this.searchFormGroup.get(val).value === null ||
        this.searchFormGroup.get(val).value === ''
      );
    });
  }

  loadCertificateDetails(version: any, blockChainId: string) {
    const v = 'v' + version;

    const link = this.certBaseUrl + '/certificate/' + v + '/' + blockChainId;
    window.open(link, '_blank');
  }

  cleanObject(obj: any) {
    for (var propName in obj) {
      if (
        obj[propName] === null ||
        obj[propName] === undefined ||
        obj[propName] === ''
      ) {
        delete obj[propName];
      }
    }
    return obj;
  }

  selectedValues(){
    for(let control of this.FRM_CONTROLS){
      if(this.searchFormGroup.get(control)?.value){
      this.selectedFormValues += this.searchFormGroup.get(control)?.value+', ';
      }
    }
  }

  captchaResolved(captchaResponse: any) {
    // console.log(`Resolved captcha with response: ${captchaResponse}`);
      this.focusSubmit();
  }

  focusSubmit(){
    
    setTimeout(()=>{
      this.buttonRef.focus();
    },10);

    // console.log('focused');
    
  }

  gotoSearchResult() {
    const el = this.searchResultsElement?.nativeElement?.querySelector('.search_results');
    el?.scrollIntoView({ behavior: 'smooth'});
}
}
