<div class="row footer">
  <div class="footer-left col-lg-8 col-md-12">
    <div class="footer-fields">
      <div class="footer-nav-item">
        <a
          target="_blank"
          href="https://www.dnv.com/about"
          matTooltip="About DNV"
          matTooltipPosition="above"
          >About DNV</a
        >
      </div>
      <div class="footer-nav-item">
        <a
          target="_blank"
          href="https://www.dnv.com/contact"
          matTooltip="Contact DNV"
          matTooltipPosition="above"
          >Contact DNV</a
        >
      </div>
      

      <div class="footer-nav-item">
        <a
          target="_blank"
          href="https://www.dnv.com/terms"
          matTooltip="Terms of use"
          matTooltipPosition="above"
          >Terms of use</a
        >
      </div>
      <div class="footer-nav-item">
        <a
          target="_blank"
          href="https://www.dnv.com/privacy"
          matTooltip="Privacy Statement"
          matTooltipPosition="above"
          >Privacy Statement</a
        >
      </div>
      <div class="footer-nav-item">
        <a
          target="_blank"
          href="https://www.dnv.com/copyright"
          matTooltip="Copyright"
          matTooltipPosition="above"
          >Copyright</a
        >
      </div>
    </div>
  </div>

  <div class="footer-right col-lg-4">
    <div class="footer-copyright">© DNV AS 2023</div>
    <mat-divider [vertical]="true"></mat-divider>

    <ul class="navbar-nav">
      <div class="footer-icons">
        <a
          href="https://www.linkedin.com/company/dnv"
          matTooltip="LinkedIn"
          matTooltipPosition="above"
          target="_blank"
        >
          <img
            class="footer-social-media-img"
            src="./assets/images/linkedin.png"
          />
        </a>
        <a
          href="https://www.twitter.com/DNV_Group"
          matTooltip="Twitter"
          matTooltipPosition="above"
          target="_blank"
        >
          <img
            class="footer-social-media-img"
            src="./assets/images/twitter.png"
          />
        </a>
        <a
          href="https://www.facebook.com/dnvofficial"
          matTooltip="Facebook"
          matTooltipPosition="above"
          target="_blank"
        >
          <img
            class="footer-social-media-img"
            src="./assets/images/facebook.png"
          />
        </a>
      </div>
    </ul>
  </div>
</div>
