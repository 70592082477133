import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  constructor(private toastrService: ToastrService) {}

  public success(message: any, title: any): void {
    this.toastrService.success(message, title, { enableHtml: true });
  }
  public info(message: any, title: any): void {
    this.toastrService.info(message, title, { enableHtml: true });
  }
  public warning(message: any, title: any): void {
    this.toastrService.warning(message, title, { enableHtml: true });
  }
  public error(message: any, title: any): void {
    this.toastrService.error(message, title, { enableHtml: true });
  }
}
