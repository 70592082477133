export const DATE_FORMATS = {
    'longDateWithSpace' : 'dd MMMM yyyy'
}

export const BUTTON_LABELS = {
    'searchBtn' : 'Search',
    'cancelBtn' : 'Cancel',
    'submitBtn' : 'Submit',
    'clearBtn' : 'Clear',
}

export const APP_LABELS = {
    'appHeading' : 'CertificateChecker'
}