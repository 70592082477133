import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { API } from '../../constants/apiURLs.constants';

@Injectable({
  providedIn: 'root',
})
export class ContactUsService {
  error: any;
  apiURL: any;
  baseUrl = environment.baseUrl;
  apimHeader = environment.apimHeader;
  payLoad: any;

  constructor(private http: HttpClient) { }

  postSupportRequest(data: any, token: any) {
    this.apiURL = this.baseUrl + API.postSupportRequest;
    // this.apiURL = '/api/' + API.postSupportRequest;

    this.payLoad = data;
    let httpOptions = {
      params: {
        'Captcha-Token': token,
      },
      headers: this.apimHeader
    };

    return this.http.post(this.apiURL, this.payLoad, httpOptions);
  }
}
