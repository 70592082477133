import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";

import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppRoutingModule } from "./app-routing.module";
import { MatDividerModule } from "@angular/material/divider";
import { HomeComponent } from "./components/home/home.component";
import { ContactUsComponent } from "./components/contact-us/contact-us.component";

import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatButtonModule } from "@angular/material/button";
import { MatSelectModule } from "@angular/material/select";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatMenuModule } from "@angular/material/menu";
import { MatIconModule } from "@angular/material/icon";
import { HeaderComponent } from "./layouts/header/header.component";
import { FooterComponent } from "./layouts/footer/footer.component";
import { FormsModule } from "@angular/forms";
import {
  RECAPTCHA_LOADER_OPTIONS,
  RECAPTCHA_SETTINGS,
  RecaptchaFormsModule,
  RecaptchaModule,
  RecaptchaSettings
} from "ng-recaptcha";
import { environment } from "../environments/environment";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { ReactiveFormsModule } from "@angular/forms";
import { ToastrModule } from 'ngx-toastr';
import { SearchPageComponent } from "./components/home/search-page/search-page.component";
import {MatCardModule} from '@angular/material/card';


@NgModule({ declarations: [
        AppComponent,
        HomeComponent,
        ContactUsComponent,
        HeaderComponent,
        FooterComponent,
        SearchPageComponent
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        MatDividerModule,
        MatFormFieldModule,
        MatInputModule,
        MatCheckboxModule,
        MatButtonModule,
        MatCardModule,
        MatSelectModule,
        MatToolbarModule,
        MatMenuModule,
        MatIconModule,
        MatTooltipModule,
        FormsModule,
        RecaptchaFormsModule,
        RecaptchaModule,
        MatProgressSpinnerModule,
        ReactiveFormsModule,
        ToastrModule.forRoot()], providers: [
        {
            provide: RECAPTCHA_SETTINGS,
            useValue: {
                siteKey: environment.recaptchaV2.siteKey,
            } as RecaptchaSettings,
        },
        {
            provide: RECAPTCHA_LOADER_OPTIONS,
            useValue: {
                onBeforeLoad(_url: any) {
                    return {
                        url: new URL("https://www.recaptcha.net/recaptcha/api.js"), // use recaptcha.net script source for some of our users
                    };
                },
            },
        },
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class AppModule {}
