import { Component, Input, OnInit } from "@angular/core";
import { APP_LABELS } from '../../constants/utils';

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  @Input() rightMenu = true;
  appLabels = APP_LABELS;

  constructor() {}

  ngOnInit(): void {
    this.appLabels = APP_LABELS;
  }
}
