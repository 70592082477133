export const ToastMessage = {
  supportFormSubmittedSuccessfully: 'Your request is submitted successfully',
  searchFormSubmitted:
    'Work In Progress!! Search Functionality Under Development!',
  supportFormError: 'Something went wrong! Please try again',
};

export const CommonMessage = {
  certNotFound:
    'The QR code scanned is not linked to a valid certificate issued by DNV.',
  certWarningMsg:
    '<p>The QR code you have scanned links to a previous version of this certificate.</p><p>Below are the details of the latest valid certificate issued by DNV.</p>',
  certExpired: '<p>Please note that the certificate is Expired.</p>',
  parentCertLinkMsg: '<p><i>This certificate has an associated parent certificate.</i></p>',
};

export const SupportUsDropdown = [
  { value: 'certMissing', text: 'Certificate is missing' },
  { value: 'dataIncorrect', text: 'Data not correct' },
  { value: 'other', text: 'Other' },
];

export const DescriptionMessage: { [index: string]: any } = {
  certMissing: 'Certificate Number',
  dataIncorrect: 'Certificate Number',
  other: 'Full Description',
};
