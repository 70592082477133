<div class="spinner-div">
  @if(isLoading){
  <mat-spinner></mat-spinner>
  }
</div>
<div class="searchpage-main-container">
  <div class="searchpage-banner row">
    <div class="col-lg-12">
      <img class="searchpage-banner-img" src="./assets/images/search-page-banner.png" />
      <div class="banner-content">
        <div class="banner-heading">{{appLabels.appHeading}}</div>
        <div class="banner-text">
          This list contains valid management system and product certificates
          issued by DNV on a global and local level.
        </div>
      </div>
    </div>
  </div>
  <div class="containerMiddle">
    <div class="row card-container">
      <div class="col-lg-3 col-md-12 col-sm-12 left">
        <form [formGroup]="searchFormGroup" (ngSubmit)="onSubmit()">
          <div id="cc-searchform" class="form-container">
            <div class="searchForm">
              <div class="searchForm-header">Find certificate</div>
              <mat-form-field>
                <mat-label>Certificate Number</mat-label>
                <input matInput placeholder="Certificate Number" formControlName="certNumberControl" />
              </mat-form-field>
              <mat-form-field>
                <mat-label>Company Name / Site Name</mat-label>
                <input matInput placeholder="Company Name / Site Name" formControlName="companyNameControl" />
              </mat-form-field>

              <mat-form-field>
                <mat-label>City</mat-label>
                <input matInput placeholder="City" formControlName="cityControl" />
              </mat-form-field>
              <mat-form-field>
                <mat-label>Country</mat-label>
                <!-- <input matInput placeholder="Country" /> -->
                <mat-select formControlName="countryControl" title="{{searchFormGroup.get('countryControl')?.value}}">
                  <mat-option *ngFor="let data of countries" [value]="data">{{
                    data
                    }}</mat-option>
                </mat-select>
                @if(searchFormGroup.get('countryControl')?.value && searchFormGroup.get('countryControl')?.value !== ''){
                <button matSuffix mat-icon-button aria-label="Clear"
                  (click)="searchFormGroup.get('countryControl').setValue(null); $event.stopPropagation()">
                  <mat-icon>x</mat-icon>
                </button>
              }
              </mat-form-field>
              
              <div class="cc-recaptcha col-sm-10">
                <re-captcha id="recaptcha" name="recaptcha" #recaptcha="ngModel" [(ngModel)]="token" (resolved)="captchaResolved($event)" required
                  [ngModelOptions]="{standalone: true}"
                  [class.is-invalid]="recaptcha.invalid && (recaptcha.dirty || recaptcha.touched)"></re-captcha>
                <div class="recaptcha-error">
                  @if(!isCaptcha() && isSubmitted){
                  <div>This field is required.</div>
                  }
                </div>
              </div>
            
            </div>
            <div class="row">
              <div class="searchForm-footer col-lg-12 col-md-12">
                <button type="reset" class="cancel-btn col-lg-4 col-md-2 col-sm-12" mat-raised-button
                  (click)="onClear()">
                  {{ btnLabels.clearBtn }}
                </button>
                <button type="submit" #searchBtnRef="matButton" class="submit-btn col-lg-4 col-md-2 col-sm-12" mat-raised-button
                  >
                  {{ btnLabels.searchBtn }}
                </button>

                <div class="search-form-erorr">
                  @if(isEmptyForm() && isSubmitted) {
                  <mat-error>Insert at least one search parameter</mat-error>
                  }
                </div>
              </div>


            </div>
          </div>
        </form>
      </div>

      <div class="col-lg-9 col-md-12 col-sm-12 main-content">
        @if(!isSearch){
        <div class="certificate-warning">
          <div class="find-certificate">Find a valid certificate</div>
          <div class="find-certificate-warning">
            This list contains valid management system and product certificates
            issued by DNV on a global and local level. A search will give a
            customer’s certificate information, verifying that this company is
            certified by us, according to which standard, certification scope,
            etc.
          </div>
        </div>
        } @else{
        <div>
          <div class="search_results">
            Search Results : @if(isSearchData){
            <span>{{ certSearchCount > certSearchLimit ? certSearchLimit+'+' : certSearchCount }}
              {{ certSearchCount > 1 ? 'entries' : 'entry' }} found</span>
            }
          </div>
          @if(!isSearchData){
          <div class="no-found">
            <img class="no-found-img" src="./assets/images/no-found-image.png" />
            <div class="no-found-msg1">
              We couldn’t find any matches for {{selectedFormValues}}
            </div>
            <div class="no-found-msg2">
              Double check your search for any typos or spelling errors.
            </div>
          </div>
          } @else{
          <div class="search-result-data">
            <div class="search-result-message">
              The search results below provide the total number of entries found
              based on your query, listed alphabetically. It will only list the
              first {{certSearchLimit}} entries. To see others, please refine the query to be
              more exact, e.g. include both company name and country (or city).
            </div>
            <div class="serach-results-container">
              @for(data of certSearchValues; track data;){
              <div class="search-results-card">
                <div class="search-results-inner">
                  <table class="search-results-desktop">
                    <tr>
                      <th class="static-cell">Certificate Number</th>
                      <td class="data-cell">
                        {{ data?.displayName }}
                      </td>
                      <th class="static-cell static-cell-company">Company Name</th>
                      <td class="data-cell">
                        {{ data?.accountName }}
                      </td>
                    </tr>
                    <tr>
                      <th class="static-cell">Standard</th>
                      <td class="data-cell">
                        {{data?.schemeServiceStandard?.[0]?.name}}
                      </td>
                      <th class="static-cell static-cell-accred">Accreditation</th>
                      <td class="data-cell">
                        {{ data?.accreditation }}
                      </td>
                    </tr>
                    <tr>
                      <th class="static-cell">Scope</th>
                      <td colspan="3" class="data-cell">
                        {{ data?.scope }}
                      </td>
                    </tr>
                    <tr>
                      <th class="static-cell">Sites ({{data.sites?.length}})</th>
                    </tr>
                  </table>
                  <table class="search-results-tab-mb">
                    <tr>
                      <th class="static-cell">Certificate Number</th>
                      <td class="data-cell">
                        {{ data?.displayName }}
                      </td>
                    </tr>
                    <tr>
                      <th class="static-cell">Company Name</th>
                      <td class="data-cell">
                        {{ data?.accountName }}
                      </td>
                    </tr>
                    <tr></tr>
                    <tr>
                      <th class="static-cell">Standard</th>
                      <td class="data-cell">
                        {{data?.schemeServiceStandard?.[0]?.name}}
                      </td>
                    </tr>
                    <tr>
                      <th class="static-cell">Accreditation</th>
                      <td class="data-cell">
                        {{ data?.accreditation }}
                      </td>
                    </tr>
                    <tr>
                      <th class="static-cell">Scope</th>
                      <td colspan="3" class="data-cell">
                        {{ data?.scope }}
                      </td>
                    </tr>
                    <tr>
                      <th class="static-cell">Sites ({{data.sites?.length}})</th>
                    </tr>
                  </table>
                </div>
                <div class="site-results-table-wrapper">
                  @if(data.sites?.length > 0){
                  <table class="site-results-table">
                    <tr class="site-table-header">
                      <th class="th_sr_no">No.</th>
                      <!-- static -->
                      <th class="th_site_name">Site Name</th>
                      <!-- static -->
                      <th class="th_site_city">City</th>
                      <!-- static -->
                      <th class="th_site_country">Country</th>
                      <!-- static -->
                    </tr>
                    @for(site of data.sites ; track site; let i=$index;){
                    @if(i < 5){ <tr class="site-table-body-lg-md">
                      <td>{{ i + 1 }}</td>
                      <td>
                        <span class="sitename-with-svg">{{ site.name }}</span>
                        <span class="ps-svg">
                          @if(site.hqFlag){
                          <img class="ps-img" title="primary site" src="./assets/images/site-flag-blue.png" />
                          }
                        </span>
                      </td>
                      <td>{{ site.city }}</td>
                      <td>
                        {{ site.country }}
                      </td>
                      </tr>
                      <tr class="site-table-body-sm">
                        <td class="site-table-mobile_td">
                          <table class="site-result-mobile">
                            <tr>
                              <th class="mob_th_width">No.</th>
                              <td class="sequenceId mob_td_width">{{ i + 1 }}</td>
                            </tr>
                            <tr>
                              <th class="mob_th_width">Site Name</th>
                              <td class="mob_td_width">
                                
                                <span class="sitename-with-svg">
                                  {{ site.name }}
                                </span>
                                <span class="ps-svg">
                                  @if(site.hqFlag){
                                  <img class="ps-img" title="primary site" src="./assets/images/site-flag-blue.png" />
                                  }
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <th class="mob_th_width">City</th>
                              <td class="mob_td_width">
                                {{ site.city }}
                              </td>
                            </tr>
                            <tr>
                              <th class="mob_th_width">Country</th>
                              <td class="mob_td_width">{{ site.country}}</td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                      }
                      }
                  </table>
                  }
                </div>
                <div class="more-details">
                  <span (click)="loadCertificateDetails(data.version,data.blockchainId)"> Click here for more details >
                  </span>
                </div>
              </div>
              }
            </div>
          </div>
          }
        </div>
        }
      </div>
    </div>
  </div>
</div>