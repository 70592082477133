<div class="headerMain">
  <div class="row">
    <nav id="headerNav">
      <ul>
        <li class="logotype">
          <div class="col-lg-2 col-md-2 col-sm-2">
            <a href="https://certificatechecker.dnv.com/">
              <img class="logo" src="./assets/images/DNV-logo.png" />
            </a>
          </div>
        </li>
        @if(rightMenu) {
        <li class="menu">
          <a href="https://certificatechecker.dnv.com/" class="headerName"
            >
            {{appLabels.appHeading}}
          </a>
          <mat-divider [vertical]="true"></mat-divider>

          <a
            href="https://www.veracity.com"
            matTooltip="Veracity.com"
            matTooltipPosition="below"
            class="menu-item"
            target="_blank"
            ><img class="veracity-img" src="./assets/images/login.png"
          /></a>
          <a
            href="/contact"
            matTooltip="Support"
            matTooltipPosition="below"
            target="_blank"
            class="menu-item"
          >
            <img class="support-img" src="./assets/images/customer-care.png"
          /></a>
        </li>
        }
      </ul>
    </nav>
  </div>
</div>
