import { HttpHeaders } from '@angular/common/http';

export const environment:any = {
  production: false,
  downTime: false,
  baseUrl:'https://testapi.dnv.com/bacertchecker-search-ui-dev/',
  apimHeader:{
    'Ocp-Apim-Subscription-Key': 'eb23dad4be2c489ab484e6e603a5a090'
  },
  recaptchaV2: {
    siteKey: '6LcJAWwpAAAAAL2nY_qgIKD8jhvhPOwImolLS22E',
  },
  certificateBaseUrl:'https://certificatechecker-dev.dnv.com'
};
