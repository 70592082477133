import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { jsonUrl } from '../../../constants/jsonPaths.constants';
import { environment } from '../../../../environments/environment';
import { API } from '../../../constants/apiURLs.constants';

@Injectable({
  providedIn: 'root'
})
export class SearchPageService {
  error: any;
  apiURL: any;
  baseUrl = environment.baseUrl;
  apimHeader = environment.apimHeader;
  payLoad: any;

  constructor(private http: HttpClient) { }
  getCountryList() {
    return this.http.get(jsonUrl.getCountries);
  }

  getCertificates(data: any) {
    this.apiURL = this.baseUrl + API.certificateSearch;
    //this.apiURL = '/api/' + API.certificateSearch;
    let httpOptions = { headers: this.apimHeader };
    this.payLoad = data;
    return this.http.post(this.apiURL, this.payLoad, httpOptions);
  }
}
